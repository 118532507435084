.intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 90vh;
}

.intro .left {
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 70%;
}

.intro .hi {
    font-size: 4em;
    color: black;

}

.intro .name {
    font-size: 4em;
    color: #FF9800;
    font-weight: bold;
}

.intro .desc {
    font-size: 0.8em;
    color: #1f1f1f;
}

.intro .social img {
    width: 32px;
    margin: 0px 20px;
    cursor: pointer;
}

.right {
    flex: 1;
    position: relative;
}

.right>* {
    position: absolute;
    z-index: 1;
}

.right .humble {
    right: 10px;
    bottom: 15%;
}

.right .vec {
    width: 65%;
    top: 20%;
    right: 15%;
}

.right .person {
    width: 50%;
    left: 25%;
    top: 8%;
}

.right .skill {
    background: white;
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 1px 1px 50px 1px rgb(255, 192, 75);
}

.right .skill1 {
    left: 10%;
    top: 20%;
}

.right .skill2 {
    right: 62%;
    bottom: 20%;
}

.right .skill3 {
    right: 5%;
    bottom: 40%;
}

.right .blur1 {
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #f7a0be;
    filter: blur(150px);
    z-index: -9;
    top: -20%;
    left: 50%;
}

.right .blur2 {
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #6be9fa;
    filter: blur(100px);
    z-index: -9;
    bottom: 10%;
    left: -20%;
}


@media only screen and (max-width: 860px) {
    .intro {
        display: block;
        justify-content: unset;
        height: unset;
        max-height: unset;
    }

    .intro .left {
        padding-left: 10px;
        margin-bottom: 50px;
    }

    .intro .contact {
        margin: 40px 0;
    }

    .right {
        text-align: center;
    }

    .skill,
    .sunglass,
    .humble {
        zoom: 0.5;
    }

    .right .person {
        position: relative;
        width: fit-content;
        left: unset;
        top: unset;
    }

    .right .vec {
        padding: 5% 25%;
        position: absolute;
        width: fit-content;
        width: 50%;
        left: 0;
        top: 0;
    }

    .sunglass {
        left: 0;
    }

    .right .blur1,
    .right .blur2 {
        left: unset;
        top: unset;
    }
}