.contact{
  color: white;
  border: none;
  border-radius: 100px;
  box-shadow: 0px 20px 24px 3px #ffdfb4;
  background: linear-gradient(180deg, #fdc50f, 27%, #fb982f);
  padding: 10px 25px;
  font-size: 16px;
  transition-duration: 0.5s;
  border: 1px solid #00000000;
}

.contact:hover{
  background: white;
  color: #fb982f;
  cursor: pointer;
  border: 1px solid #fb982f;
}