.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.extra_menu {
    display: none;
    font-weight: bold;
    margin: 20px;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.nav .menuicon {
    display: none;
    cursor: pointer;
}

.navhide {
    display: none;
}

.navshow {
    border-bottom: 1px solid orange;
    box-shadow: 0px 5px 5px rgb(239, 167, 32);
}

.nav .menu {
    margin: 10px;
}

.nav .menu>.menuitem {
    font-weight: bold;
    width: 50%;
    max-width: 700px;
    margin: 20px;
}

.nav .menu a:hover {
    color: #FF9800;
}

@media only screen and (max-width: 860px) {
    .nav .menuitem {
        display: none;
    }

    .extra_menu {
        display: flex;
    }

    .nav .menuicon {
        display: block;
        width: 32px;
        height: 32px;
        margin-left: 10px;
        background-image: url("/public/image/menu.png");
        background-size: cover;
    }

}

@media only screen and (max-width: 280px) {

    .nav .menu a,
    .nav .menu .contact {
        text-align: center;
        width: 100%;
    }

    .nav .menu .contact {
        margin: 0;
        margin-top: 10px;
    }
}